<!-- use tags -->
export interface Input {
  skipTo: string;
}
<a/skipLink
  class="skip"
  href=input.skipTo
  onClick() {
    const el = skipLink();
    el.setAttribute("tabindex", "-1");
    el.addEventListener(
      "blur",
      () => {
        el.removeAttribute("tabindex");
      },
      { once: true },
    );
  }
>
  Skip to main content
</a>

<style.scss>
  .skip {
    background: light-dark($color-status-success-05, $color-status-success-100);
    color: light-dark($color-neutral-95, $color-neutral-05);
    height: 1px;
    left: 0;
    overflow: hidden;
    position: absolute;
    text-decoration: none;
    top: 0;
    width: 1px;
    z-index: $z-maximum;

    &:focus {
      height: auto;
      padding: $spacing-4 $spacing-8;
      width: auto;
    }
  }
</style>
