export interface Input extends Marko.Input<"a"> {}
<a ...input class=["a", input.class]>
  <${input.renderBody}/>
</a>
style.scss {
  .a {
    color: light-dark($color-accent-60, $color-accent-40);
    font-weight: $font-weight-medium;
    text-decoration: none;
    border-radius: $spacing-4;

    &:hover {
      color: light-dark($color-accent-70, $color-accent-30);
      text-decoration: underline;
    }

    &:focus-visible {
      outline-offset: 1px;
    }
  }
}
