<!-- use tags -->
export interface Input {
  class?: string;
  large?: boolean;
  left?: boolean;
  right?: boolean;
  title: string;
  host: Marko.AttrTag<{
    renderBody: Marko.Body;
  }>;
  hostExpanded?: Marko.AttrTag<{
    renderBody: Marko.Body;
  }>;
  overlay: Marko.AttrTag<{
    renderBody: Marko.Body;
  }>;
}

<id/dropdownId/>
<div class="top-bar-dropdown" id=dropdownId>
  <details/details aria-label=input.title>
    <summary class=["top-bar__button", input.large && "top-bar__button--large"]>
      <div class="top-bar-dropdown__host-close">
        <${input.host}/>
      </div>
      <div class="top-bar-dropdown__host-open">
        <${input.hostExpanded || input.host}/>
      </div>
    </summary>
  </details>

  <div class=[
    input.class,
    "top-bar-dropdown__overlay",
    input.left && "top-bar-dropdown__overlay--left",
    input.right && "top-bar-dropdown__overlay--right",
  ]>
    <${input.overlay}/>
  </div>
</div>

<on-blur=dropdownId handler() {
  details().open = false;
}/>

<style.scss>
  .top-bar-dropdown {
    display: inline-block;
    position: relative;

    [open] &__host-close {
      display: none;
    }

    &__host-open {
      display: none;
    }

    [open] &__host-open {
      display: initial;
    }

    &__overlay {
      background: light-dark($color-neutral-00, $color-neutral-98);
      border: 1px solid light-dark($color-neutral-20, $color-neutral-70);
      border-radius: $radius-s;
      box-shadow: $elevation-dropdown;
      color: light-dark($color-neutral-98, $color-neutral-00);
      display: none;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: calc(100% + $spacing-8);
      transition:
        display $time-very-fast allow-discrete,
        opacity ease-in-out $time-very-fast;
      z-index: $z-very-high;

      &--left {
        left: $spacing-8;
        right: initial;
      }

      &--right {
        left: initial;
        right: $spacing-8;
      }
    }

    [open] + &__overlay {
      display: block;
      opacity: 1;

      @starting-style {
        opacity: 0;
      }
    }
  }
</style>
